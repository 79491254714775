(function() {
  'use strict';

  function toggleDropdown (e) {
    const _d = $(e.target).closest('.dropdown'),
        _m = $('.dropdown-menu', _d);
    setTimeout(function(){
      const shouldOpen = e.type !== 'click' && _d.is(':hover');
      _m.toggleClass('show', shouldOpen);
      _d.toggleClass('show', shouldOpen);
      $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
    }, e.type === 'mouseleave' ? 300 : 0);
  }

  $(document).ready(function() {
    // Double click on dropdown navigation
    $('.navbar-nav .nav-item .nav-link.dropdown-toggle').dblclick(function(e) {
      const href = $(this).attr('href');
      window.location.href = href;
    });

    // init tooltips
    $('body').tooltip({ selector: '[data-toggle=tooltip]' });

    // init Animate on Scroll (https://github.com/michalsnik/aos)
    AOS.init({
      once: true,
      easing: 'ease-out-back',
      duration: '1000'
    });

    // init scrollIt (http://www.bytemuse.com/scrollIt.js)
    $.scrollIt();

    $('.navbar-top')
        .on('mouseenter mouseleave','.dropdown',toggleDropdown)
        .on('click', '.dropdown-menu a', toggleDropdown);
  });
})();
